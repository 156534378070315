export default {
  english: {
    label: 'manage preferences',
  },
  french: {
    label: 'gérer mes préférences',
  },
  arabic: {
    label: 'إدارة التفضيلات',
  },
  hebrew: {
    label: 'נהל העדפות',
  },
}
