import React from 'react'
import Svg from './Svg'

// ProfileIcon :: Props -> React.Component
export default ({
  width = 25,
  height = 25,
  'aria-label': ariaLabel,
  className = '',
}) =>
  <Svg
    className={ className }
    width={ width }
    height={ height }
    viewBox="0 0 25 25"
    aria-label={ ariaLabel }
  >
    <g transform="translate(-298.000000, -1878.000000)">
      <path d="M310.5,1878 C303.596441,1878 298,1883.59644 298,1890.5 C298,1897.40356 303.596441,1903 310.5,1903 C317.403559,1903 323,1897.40356 323,1890.5 C323,1883.59644 317.403559,1878 310.5,1878 Z M299.704545,1890.5 C299.7042,1885.77191 302.780579,1881.59375 307.295573,1880.19031 C311.810567,1878.78686 316.713456,1880.48474 319.393554,1884.37985 C322.073651,1888.27497 321.90725,1893.46085 318.982955,1897.17614 C317.680526,1895.23623 315.790802,1893.76468 313.590909,1892.97727 C314.541633,1891.9596 315.062716,1890.61415 315.045455,1889.22159 C315.045455,1886.38068 313.005682,1884.10795 310.5,1884.10795 C307.994318,1884.10795 305.954545,1886.38068 305.954545,1889.22159 C305.94042,1890.66385 306.505314,1892.05152 307.522727,1893.07386 C305.307222,1893.86916 303.438516,1895.41235 302.238636,1897.4375 C300.60068,1895.49711 299.702904,1893.0393 299.704545,1890.5 L299.704545,1890.5 Z M310.784091,1892.48864 C310.47282,1892.48992 310.161814,1892.50698 309.852273,1892.53977 C308.469512,1892.03833 307.578493,1890.69026 307.659091,1889.22159 C307.659091,1887.34091 308.931818,1885.8125 310.5,1885.8125 C312.068182,1885.8125 313.340909,1887.34091 313.340909,1889.22159 C313.410921,1890.64893 312.572898,1891.96518 311.25,1892.50568 C311.096591,1892.5 310.943182,1892.48864 310.784091,1892.48864 Z M303.5,1898.73864 C304.734931,1896.32769 307.067155,1894.66969 309.75,1894.29545 C310.303116,1894.40347 310.873106,1894.3899 311.420455,1894.25568 C314.071896,1894.61372 316.412909,1896.16741 317.772727,1898.47159 C313.765814,1902.17001 307.625253,1902.28477 303.482955,1898.73864 L303.5,1898.73864 Z"/>
    </g>
  </Svg>
