export default {
  english: {
    label: 'Radio',
    link: '/en/live-radio',
  },
  french: {
    label: 'Radio',
    link: '/fr/en-direct',
  },
  arabic: {
    label: 'مذياع',
    link: '/ar/راديو-مباشر',
  },
  hebrew: {
    label: 'רדיו',
    link: '/he/live-radio',
  },
}
