export default {
  english: {
    label: 'Main menu',
  },
  french: {
    label: 'Menu principal',
  },
  arabic: {
    label: 'القائمة الرئيسية',
  },
  hebrew: {
    label: 'תפריט ראשי',
  },
}
