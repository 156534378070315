export default {
  english: {
    signIn: 'Sign In',
    email: 'E-mail',
    password: 'Password',
    badCredentials: 'Bad credentials',
    unverified: 'Unverified email',
    reset: 'Reset password',
    termsText: 'By signing in, you accept our',
    terms: 'terms of use.',
    termsUrl: '/en/terms-of-service',
    noAccountYet: 'Don\'t have an account yet?',
    signUp: 'Sign Up',
    mandatoryFields: 'Fields marked with * are mandatory',
    errors: {
      invalidEmail: 'Email field should be a valid email address',
    },
  },
  french: {
    signIn: 'Connexion',
    email: 'E-mail',
    password: 'Mot de passe',
    badCredentials: 'Mauvais identifiants',
    unverified: 'E-mail non vérifié',
    reset: 'Réinitialiser le mot de passe',
    termsText: 'En vous connectant, vous acceptez nos',
    terms: 'conditions d\'utilisation.',
    termsUrl: '/fr/conditions-generales-d-utilisation',
    noAccountYet: 'Vous n\'avez pas encore de compte ?',
    signUp: 'Inscription',
    mandatoryFields: 'Les champs marqués d\'un * sont obligatoires',
    errors: {
      invalidEmail: 'Le champ e-mail doit être une adresse e-mail valide',
    },
  },
  arabic: {
    signIn: 'الدخول',
    email: 'البريد الإلكتروني',
    password: 'كلمة مرور',
    badCredentials: 'وقع خطأ',
    unverified: 'البريد الإلكتروني غير موثق',
    reset: 'مسح كلمة المرور',
    termsText: 'بتسجيل الدخول ، فأنت تقبل',
    terms: 'شروط الاستخدام.',
    termsUrl: '/ar/شروط-الخدمة',
    noAccountYet: 'ليس لك حساب بعد',
    signUp: 'الخروج',
    mandatoryFields: 'الحقول التي تحمل علامة * إلزامية',
    errors: {
      invalidEmail: 'يجب أن يكون حقل البريد الإلكتروني عنوان بريد إلكتروني صالح',
    },
  },
  hebrew: {
    signIn: 'התחבר',
    email: 'דואר אלקטרוני',
    password: 'סיסמה',
    badCredentials: 'משתמש וסיסמה לא נכונים',
    unverified: 'דואר אלקטרוני לא מאומת',
    reset: 'אפס סיסמה',
    termsText: 'בהתחברות אתה מסכים ל',
    terms: 'תנאי שימוש שלנו',
    termsUrl: '/he/terms-of-service',
    noAccountYet: 'עדיין אין ברשותך מנוי?',
    signUp: 'הירשם',
    mandatoryFields: 'שדות המסומנים ב-* הם חובה',
    errors: {
      invalidEmail: 'כתובת האימייל לא תקינה',
    },
  },
}
