export default {
  english: {
    closeAdvert: 'Close advertising',
    topStoriesLabel: 'News',
    newsLabel: 'News feed',
    newsLink: '/en/news',
    liveLabel: 'Live',
    liveLink: 'https://video.i24news.tv?lang=en',
    liveFree: 'Free',
    radioLabel: 'Radio',
    radioLink: '/en/live-radio',
    showsLabel: 'Shows',
    showsLink: '/en/shows',
  },
  french: {
    closeAdvert: 'Fermer la publicité',
    topStoriesLabel: 'Actu',
    newsLabel: 'Fil info',
    newsLink: '/fr/fil-info',
    liveLabel: 'Direct',
    liveLink: 'https://video.i24news.tv?lang=fr',
    liveFree: 'Gratuit',
    radioLabel: 'Radio',
    radioLink: '/fr/en-direct',
    showsLabel: 'Émissions',
    showsLink: '/fr/emissions',
  },
  arabic: {
    closeAdvert: 'إغلاق الإعلان',
    topStoriesLabel: 'أخبار',
    newsLabel: 'أخبار هامة',
    newsLink: '/ar/آخر-الاخبار',
    liveLabel: 'مباشر',
    liveLink: 'https://video.i24news.tv?lang=ar',
    liveFree: 'مجانا',
    radioLabel: 'مذياع',
    radioLink: '/ar/راديو-مباشر',
    showsLabel: 'برنامج',
    showsLink: '/ar/برنامج',
  },
  hebrew: {
    closeAdvert: 'סגור פרסומת',
    topStoriesLabel: 'חדשות',
    newsLabel: 'פיד חדשות',
    newsLink: '/he/news',
    liveLabel: 'Live',
    liveLink: 'https://video.i24news.tv?lang=he',
    liveFree: 'Free',
    radioLabel: 'רדיו',
    radioLink: '/he/live-radio',
    showsLabel: 'תוכניות',
    showsLink: '/he/shows',
  },
}
