export default {
  english: {
    videosLabel: 'Videos',
    videosLink: 'https://video.i24news.tv?lang=en',
    showsLabel: 'Shows',
    showsLink: '/en/shows',
    schedulesLabel: 'Schedules',
    schedulesLink: '/en/schedules',
    channelsLabel: 'Channels',
    channelsLink: '/en/channels',
    profilesLabel: 'Profiles',
    profilesLink: '/en/profiles',
  },
  french: {
    videosLabel: 'Vidéos',
    videosLink: 'https://video.i24news.tv?lang=fr',
    showsLabel: 'Émissions',
    showsLink: '/fr/emissions',
    schedulesLabel: 'Programmes',
    schedulesLink: '/fr/grille-des-programmes',
    channelsLabel: 'Canaux',
    channelsLink: '/fr/canaux',
    profilesLabel: 'Profils',
    profilesLink: '/fr/profils',
  },
  arabic: {
    videosLabel: 'أشرطة فيديو',
    videosLink: 'https://video.i24news.tv?lang=ar',
    showsLabel: 'برنامج',
    showsLink: '/ar/برنامج',
    schedulesLabel: 'جداول',
    schedulesLink: '/ar/جداول',
    channelsLabel: 'القنوات',
    channelsLink: '/ar/القنوات',
    profilesLabel: '',
    profilesLink: '',
  },
  hebrew: {
    videosLabel: 'VOD',
    videosLink: 'https://video.i24news.tv?lang=he',
    showsLabel: 'תוכניות',
    showsLink: '/he/shows',
    schedulesLabel: 'לוח שידורים',
    schedulesLink: '/he/schedules',
    channelsLabel: 'ערוצים',
    channelsLink: '/he/channels',
    profilesLabel: 'פרופילים',
    profilesLink: '',
  },
}
