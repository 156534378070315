export default {
  english: {
    open: 'Open search panel',
    close: 'Close search panel',
  },
  french: {
    open: 'Ouvrir le panneau de recherche',
    close: 'Fermer le panneau de recherche',
  },
  arabic: {
    open: 'افتح لوحة البحث',
    close: 'إغلاق لوحة البحث',
  },
  hebrew: {
    open: 'פתח פאנל חיפוש',
    close: 'סגור פאנל חיפוש',
  },
}
