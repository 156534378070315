export default {
  english: {
    breadcrumb: 'Breadcrumb',
    homepage: 'i24NEWS',
  },
  french: {
    breadcrumb: 'Fil d\'Ariane',
    homepage: 'i24NEWS',
  },
  arabic: {
    breadcrumb: 'فتات الخبز',
    homepage: 'i24NEWS',
  },
  hebrew: {
    breadcrumb: 'פירורי לחם',
    homepage: 'i24NEWS',
  },
}
