export default {
  english: {
    toggle: 'Press the enter key to open and close the dropdown menu',
    toggleIcon: 'Dropdown menu icon',
  },
  french: {
    toggle: 'Appuyez sur la touche entrée pour ouvrir et fermer le menu déroulant',
    toggleIcon: 'Icône du menu déroulant',
  },
  arabic: {
    toggle: 'اضغط على مفتاح الإدخال لفتح وإغلاق القائمة المنسدلة',
    toggleIcon: 'رمز القائمة المنسدلة',
  },
  hebrew: {
    toggle: 'הקש על מקש להיכנס כדי להרחיב או לסגור את התפריט',
    toggleIcon: 'סמל תפריט נפתח',
  },
}
