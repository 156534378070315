export default {
  english: {
    open: 'Open main menu',
    close: 'Close main menu',
  },
  french: {
    open: 'Ouvrir le menu principal',
    close: 'Fermer le menu principal',
  },
  arabic: {
    open: 'فتح القائمة الرئيسية',
    close: 'إغلاق القائمة الرئيسية',
  },
  hebrew: {
    open: 'פתח תפריט ראשי',
    close: 'סגור תפריט ראשי',
  },
}
