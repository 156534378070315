export default {
  english: {
    default: {
      content: 'An unexpected error occurred',
      title: 'Page 500',
    },
    back: 'Back to the homepage',
    http204: {
      title: 'Page 204',
      content: 'There is no content in that section',
    },
    http404: {
      title: 'Page 404',
      content: 'The page you are looking for could not be found',
    },
  },
  french: {
    default: {
      content: 'Une erreur inattendue s\'est produite',
      title: 'Page 500',
    },
    back: 'Retour à la page d\'accueil',
    http204: {
      title: 'Page 204',
      content: 'Cette section est vide. Revenez plus tard !',
    },
    http404: {
      title: 'Page 404',
      content: 'La page que vous recherchez n\'a pas pu être trouvée',
    },
  },
  arabic: {
    default: {
      content: 'حدث خطأ غير متوقع',
      title: 'صفحة 500',
    },
    back: 'الرجوع إلى الصفحة الرئيسية',
    http204: {
      title: 'صفحة 204',
      content: 'لا يوجد محتوى في هذا القسم',
    },
    http404: {
      title: 'صفحة 404',
      content: 'الصفحة التي تبحث عنها لا يمكن العثور عليها',
    },
  },
  hebrew: {
    default: {
      content: 'שגיאה בלתי צפויה',
      title: 'עמוד 500',
    },
    back: 'בחזרה לעמוד הבית',
    http204: {
      title: 'עמוד 204',
      content: 'אין תוכן בעמוד/ בסעיף',
    },
    http404: {
      title: 'עמוד 404/ שגיאה! עמוד 404',
      content: 'הדף שחיפשת לא נמצא',
    },
  },
}
