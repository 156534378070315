export default {
  english: {
    skip: 'Skip advertisement',
  },
  french: {
    skip: 'Passer la publicité',
  },
  arabic: {
    skip: 'تخطي الإعلان',
  },
  hebrew: {
    skip: 'דלג מודעה',
  },
}
