export default {
  english: {
    open: 'Show sub-categories',
    close: 'Hide sub-categories',
  },
  french: {
    open: 'Afficher les sous-catégories',
    close: 'Masquer les sous-catégories',
  },
  arabic: {
    open: 'إظهار الفئات الفرعية',
    close: 'إخفاء الفئات الفرعية',
  },
  hebrew: {
    open: 'הראה קטגוריות משנה',
    close: 'הסתר קטגוריות משנה',
  },
}
