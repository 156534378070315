export default {
  english: {
    close: 'Close sign in modal',
  },
  french: {
    close: 'Fermer le modal de connexion',
  },
  arabic: {
    close: 'إغلاق نموذج تسجيل الدخول',
  },
  hebrew: {
    close: 'סגור פאנל התחברות',
  },
}
