export default {
  english: {
    label: 'Categories',
  },
  french: {
    label: 'Catégories',
  },
  arabic: {
    label: 'فئات',
  },
  hebrew: {
    label: 'קטגוריות',
  },
}
