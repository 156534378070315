import React from 'react'
import Svg from './../Svg'

// TimelineIcon :: Props -> React.Component
export default ({
  width = 30,
  height = 25,
  'aria-label': ariaLabel,
  className = '',
}) =>
  <Svg
    className={ className }
    width={ width }
    height={ height }
    viewBox="0 0 19 25"
    aria-label={ ariaLabel }
  >
    <path d="M2,0.7c1.3-0.3,2.7,0.5,3,1.9c0.3,1.3-0.5,2.7-1.9,3l0,0v4.6C4.2,10.3,5,11.3,5,12.5c0,1.2-0.8,2.2-1.9,2.4l0,0v4.6C4.2,19.8,5,20.8,5,21.9c0,1.4-1.1,2.5-2.5,2.5c-1.4,0-2.5-1.1-2.5-2.5c0-1.1,0.8-2.2,1.9-2.4l0,0v-4.6C1,14.7,0.3,14,0.1,13c-0.3-1.3,0.6-2.6,1.9-2.9l0,0V5.5C0.8,5.2,0.1,4.2,0.1,3.1C0.1,1.9,0.9,0.9,2,0.7z M2.6,20.6c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3c0.7,0,1.3-0.6,1.4-1.3C3.9,21.2,3.3,20.6,2.6,20.6z M17.4,21.9c0.5,0,0.9,0.3,0.9,0.6S17.9,23,17.4,23l0,0H8.2c-0.5,0-0.9-0.3-0.9-0.6s0.4-0.6,0.9-0.6l0,0H17.4z M12.5,20.2c0.4,0,0.8,0.3,0.8,0.6c0,0.3-0.3,0.6-0.8,0.6l0,0H8.1c-0.4,0-0.8-0.3-0.8-0.6c0-0.3,0.3-0.6,0.8-0.6l0,0H12.5z M17.4,12.8c0.5,0,0.9,0.3,0.9,0.6c0,0.3-0.4,0.6-0.9,0.6l0,0H8.2c-0.5,0-0.9-0.3-0.9-0.6c0-0.3,0.4-0.6,0.9-0.6l0,0H17.4z M2.6,11.2c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3l0,0c0.7,0,1.3-0.6,1.3-1.3C3.9,11.8,3.3,11.2,2.6,11.2z M12.5,11.1c0.4,0,0.8,0.3,0.8,0.6s-0.3,0.6-0.8,0.6l0,0H8.1c-0.4,0-0.8-0.3-0.8-0.6s0.3-0.6,0.8-0.6l0,0H12.5z M17.4,3.7c0.5,0,0.9,0.3,0.9,0.6s-0.4,0.6-0.9,0.6l0,0H8.2c-0.5,0-0.9-0.3-0.9-0.6s0.4-0.6,0.9-0.6l0,0H17.4z M2.6,1.7c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3c0.7,0,1.3-0.6,1.4-1.3C3.9,2.3,3.3,1.7,2.6,1.7z M12.5,2c0.4,0,0.8,0.3,0.8,0.6c0,0.3-0.3,0.6-0.8,0.6l0,0H8.1c-0.4,0-0.8-0.3-0.8-0.6C7.3,2.2,7.6,2,8.1,2l0,0H12.5z"/>
  </Svg>
