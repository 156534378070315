export default {
  english: {
    mobileAppsLabel: 'Mobile apps',
    informationTitle: 'Information',
    leadershipLabel: 'i24NEWS EXECUTIVE COMMITTEE',
    leadershipLink: '/en/executive-committee',
    anchorsLabel: 'i24NEWS PROFILES',
    anchorsLink: '/en/profiles',
    showsLabel: 'i24NEWS TV SHOWS',
    showsLink: '/en/shows',
    liveRadioLabel: 'Live radio',
    liveRadioLink: '/en/live-radio',
    contactLabel: 'Contact',
    contactLink: '/en/contact',
    sitemapLabel: 'Sitemap',
    sitemapLink: '/en/sitemap',
    categoriesTitle: 'Categories',
    breakingNewsLabel: 'Breaking News',
    breakingNewsLink: '/en/news',
    legalTitle: 'Legal',
    termsOfServiceLabel: 'Terms of service',
    termsOfServiceLink: '/en/terms-of-service',
    privacyPolicyLabel: 'Privacy policy',
    privacyPolicyLink: '/en/privacy-policy',
    advertisingTermsAndConditionsLabel: 'Advertising Terms and Conditions',
    advertisingTermsAndConditionsLink: '/en/advertising-terms-and-conditions',
    accessibilityDeclarationLabel: 'Accessibility declaration',
    accessibilityDeclarationLink: '/en/accessibility-declaration',
    cookiesLabel: 'Cookie list',
    cookiesLink: '/en/cookie-list',
    socialTitle: 'Follow us',
    newsletterLink: '/en/newsletter',
    newsletterLabel: 'Subscribe to newsletter',
  },
  french: {
    mobileAppsLabel: 'Application mobile',
    informationTitle: 'Information',
    leadershipLabel: 'COMITÉ EXÉCUTIF D\'i24NEWS',
    leadershipLink: '/fr/comite-executif',
    anchorsLabel: 'PROFILS D\'i24NEWS',
    anchorsLink: '/fr/profiles',
    showsLabel: 'NOS ÉMISSIONS',
    showsLink: '/fr/emissions',
    liveRadioLabel: 'Radio en direct',
    liveRadioLink: '/fr/en-direct',
    contactLabel: 'Contact',
    contactLink: '/fr/contact',
    sitemapLabel: 'Plan du site',
    sitemapLink: '/fr/plan-du-site',
    categoriesTitle: 'Categories',
    breakingNewsLabel: 'Fil info',
    breakingNewsLink: '/fr/fil-info',
    legalTitle: 'Juridique',
    termsOfServiceLabel: 'Conditions générales d\'utilisation',
    termsOfServiceLink: '/fr/conditions-generales-d-utilisation',
    privacyPolicyLabel: 'Politique de confidentialité',
    privacyPolicyLink: '/fr/politique-de-confidentialite',
    advertisingTermsAndConditionsLabel: 'Conditions générales publicitaire',
    advertisingTermsAndConditionsLink: '/fr/conditions-generales-publicitaire',
    accessibilityDeclarationLabel: 'Déclaration d\'accessibilité',
    accessibilityDeclarationLink: '/fr/declaration-d-accessibilite',
    cookiesLabel: 'Liste des cookies',
    cookiesLink: '/fr/liste-des-cookies',
    socialTitle: 'Nous suivre',
    newsletterLink: '/fr/newsletter',
    newsletterLabel: 'S\'inscrire à la newsletter',
  },
  arabic: {
    mobileAppsLabel: 'تطبيقات الموبايل',
    informationTitle: 'معلومات',
    leadershipLabel: 'اللجنة التنفيذية i24NEWS',
    leadershipLink: '/ar/اللجنة-التنفيذية',
    anchorsLabel: '',
    anchorsLink: '',
    showsLabel: 'برنامج i24NEWS',
    showsLink: '/ar/برنامج',
    liveRadioLabel: 'الاذاعة الحية',
    liveRadioLink: '/ar/راديو-مباشر',
    contactLabel: 'اتصال',
    contactLink: '/ar/contact',
    sitemapLabel: 'خريطة الموقع',
    sitemapLink: '/ar/خريطة-الموقع',
    categoriesTitle: 'فئات',
    breakingNewsLabel: 'ملخص الأخبار',
    breakingNewsLink: '/ar/آخر-الاخبار',
    legalTitle: 'قانوني',
    termsOfServiceLabel: 'شروط الخدمة',
    termsOfServiceLink: '/ar/شروط-الخدمة',
    privacyPolicyLabel: 'سياسة خاصة',
    privacyPolicyLink: '/ar/سياسة-خاصة',
    advertisingTermsAndConditionsLabel: 'شروط وأحكام الإعلان',
    advertisingTermsAndConditionsLink: '/ar/شروط-وأحكام-الإعلان',
    accessibilityDeclarationLabel: 'إعلان إمكانية الوصول',
    accessibilityDeclarationLink: '/ar/إعلان-إمكانية-الوصول',
    cookiesLabel: 'قائمة ملفات تعريف الارتباط',
    cookiesLink: '/ar/بسكويت',
    socialTitle: 'تابعنا',
    newsletterLink: '/ar/النشرة-الإخبارية',
    newsletterLabel: 'اشترك في النشرة الإخبارية',
  },
  hebrew: {
    mobileAppsLabel: 'אפליקציות',
    informationTitle: 'מידע',
    leadershipLabel: 'הוועד המנהל של i24NEWS',
    leadershipLink: '/he/executive-committee',
    anchorsLabel: 'i24NEWS פרופיל',
    anchorsLink: '',
    showsLabel: 'תוכניות הטלוויזיה של i24NEWS',
    showsLink: '/he/shows',
    liveRadioLabel: 'רדיו בשידור חי',
    liveRadioLink: '/he/live-radio',
    contactLabel: 'צור קשר',
    contactLink: '/he/contact',
    sitemapLabel: 'מפת אתר',
    sitemapLink: '/he/sitemap',
    categoriesTitle: 'קטגוריות',
    breakingNewsLabel: 'חדשות מתפרצות',
    breakingNewsLink: '/he/news',
    legalTitle: 'משפטי',
    termsOfServiceLabel: 'תנאי שימוש',
    termsOfServiceLink: '/he/terms-of-service',
    privacyPolicyLabel: 'מדיניות פרטיות',
    privacyPolicyLink: '/he/privacy-policy',
    advertisingTermsAndConditionsLabel: 'תנאי פרסום ותנאי מכירות',
    advertisingTermsAndConditionsLink: '/he/advertising-terms-and-conditions',
    accessibilityDeclarationLabel: 'הצהרת נגישות',
    accessibilityDeclarationLink: '/he/accessibility-declaration',
    cookiesLabel: 'רשימת עוגיות',
    cookiesLink: '/he/cookie-list',
    socialTitle: 'עקבו אחרינו',
    newsletterLink: '/he/newsletter',
    newsletterLabel: 'הירשם לניוזלטר',
  },
}
