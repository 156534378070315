import React from 'react'
import Svg from './Svg'

// SearchIcon :: Props -> React.Component
export default ({
  width = 24,
  height = 25,
  'aria-label': ariaLabel,
  className = '',
}) =>
  <Svg
    className={ className }
    width={ width }
    height={ height }
    viewBox="0 0 24 25"
    aria-label={ ariaLabel }
  >
    <g transform="translate(-244.000000, -1878.000000)">
      <path d="M267.752093,1901.43209 L262.062205,1895.83106 C266.048999,1891.86077 266.170483,1885.48751 262.337831,1881.37121 C258.505179,1877.25492 252.077366,1876.85515 247.75423,1880.4642 C243.431093,1884.07325 242.745524,1890.41144 246.198791,1894.84445 C249.652057,1899.27747 256.019706,1900.23346 260.645891,1897.01343 L266.446621,1902.75464 C266.810564,1903.09028 267.377705,1903.08038 267.729459,1902.73223 C268.081212,1902.38409 268.091219,1901.82277 267.752093,1901.46256 L267.752093,1901.43209 Z M246,1888.5 C246,1883.80558 249.80558,1880 254.5,1880 C259.19442,1880 263,1883.80558 263,1888.5 C263,1893.19442 259.19442,1897 254.5,1897 C249.80558,1897 246,1893.19442 246,1888.5 Z"/>
    </g>
  </Svg>
