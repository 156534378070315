export default {
  english: {
    label: 'Get the Google Play app',
  },
  french: {
    label: 'Obtenez l\'application Google Play',
  },
  arabic: {
    label: 'احصل على تطبيق Google Play',
  },
  hebrew: {
    label: 'הורדת את אפליקציית האנדרואיד',
  },
}
