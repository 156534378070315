export default {
  english: {
    label: 'Pages',
  },
  french: {
    label: 'Pages',
  },
  arabic: {
    label: 'الصفحات',
  },
  hebrew: {
    label: 'עמודים',
  },
}
