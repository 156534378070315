export default {
  english: {
    topStories: 'Top stories',
    more: 'More',
  },
  french: {
    topStories: 'À la Une',
    more: 'Voir plus',
  },
  arabic: {
    topStories: 'الصفحة الرئيسية',
    more: 'اخرى',
  },
  hebrew: {
    topStories: 'ראשי',
    more: 'עוד',
  },
}
