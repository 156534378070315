export default {
  english: {
    label: 'Get the IOS app',
  },
  french: {
    label: 'Obtenez l\'application IOS',
  },
  arabic: {
    label: 'احصل على تطبيق IOS',
  },
  hebrew: {
    label: 'הורד את אפליקציית הIOS',
  },
}
