import React from 'react'
import Svg from './../Svg'

// SchedulesIcon :: Props -> React.Component
export default ({
  width = 25,
  height = 22,
  'aria-label': ariaLabel,
  className = '',
}) =>
  <Svg
    className={ className }
    width={ width }
    height={ height }
    viewBox="0 0 30 26"
    aria-label={ ariaLabel }
  >
    <path d="M11.4117882,0 L11.4117882,1.68831169 L18.5882118,1.68831169 L18.5882118,0 L20.7058588,0 L20.7058588,1.68831169 L30,1.68831169 L30,26 L0,26 L0,1.68831169 L9.29414118,1.68831169 L9.29414118,0 L11.4117882,0 Z M27.8823529,9.11688312 L2.11764706,9.11688312 L2.11764706,23.974026 L27.8823529,23.974026 L27.8823529,9.11688312 Z M10,18 L10,21 L6,21 L6,18 L10,18 Z M17,18 L17,21 L13,21 L13,18 L17,18 Z M24,18 L24,21 L20,21 L20,18 L24,18 Z M17,12 L17,15 L13,15 L13,12 L17,12 Z M24,12 L24,15 L20,15 L20,12 L24,12 Z M10,12 L10,15 L6,15 L6,12 L10,12 Z M9.29414118,3.71428571 L2.11764706,3.71428571 L2.11764706,7.09090909 L27.8823529,7.09090909 L27.8823529,3.71428571 L20.7059294,3.71428571 L20.7059294,5.06493506 L18.5882824,5.06493506 L18.5882824,3.71428571 L11.4117882,3.71428571 L11.4117882,5.4025974 L9.29414118,5.4025974 L9.29414118,3.71428571 Z"/>
  </Svg>
