export default {
  english: {
    label: 'Language menu',
    en: 'English',
    fr: 'Français',
    ar: 'عربى',
    he: 'עברית',
  },
  french: {
    label: 'Menu de langue',
    en: 'English',
    fr: 'Français',
    ar: 'عربى',
    he: 'עברית',
  },
  arabic: {
    label: 'قائمة اللغة',
    en: 'English',
    fr: 'Français',
    ar: 'عربى',
    he: 'עברית',
  },
  hebrew: {
    label: 'תפריט שפה',
    en: 'English',
    fr: 'Français',
    ar: 'عربى',
    he: 'עברית',
  },
}
