export default {
  english: {
    signIn: 'Sign In',
    email: 'E-mail',
    error: 'Error while resetting, try again',
    success: 'Reset password e-mail sent. If you don\'t receive an e-mail, please make sure you\'ve entered the address you registered with, and check your spam folder.',
    reset: 'Reset password',
    cancel: 'Cancel',
    back: 'Back to navigation',
    mandatoryFields: 'Fields marked with * are mandatory',
    errors: {
      invalidEmail: 'Email field should be a valid email address',
    },
  },
  french: {
    signIn: 'Connexion',
    email: 'E-mail',
    error: 'Erreur lors de la réinitialisation, réessayez',
    success: 'E-mail de réinitialisation envoyé. Si vous ne recevez pas d\'e-mail, assurez-vous d\'avoir entré l\'adresse avec laquelle vous vous êtes inscrit et vérifiez votre dossier de spam.',
    reset: 'Réinitialiser le mot de passe',
    cancel: 'Annuler',
    back: 'Retour à la navigation',
    mandatoryFields: 'Les champs marqués d\'un * sont obligatoires',
    errors: {
      invalidEmail: 'Le champ e-mail doit être une adresse e-mail valide',
    },
  },
  arabic: {
    signIn: 'الدخول',
    email: 'البريد الإلكتروني',
    error: 'وقع خطأ عند المسح حاول ثانية',
    success: 'أرسلت الى بريدك الالكتروني رسالة لمسح كلمة المرور. إذا لم تصلك الرسالة الالكترونية، فعليك التأكد من العنوان الذي أدرجته عند التسجيل وكذلك فحص مجلد الرسائل العشوائية في بريدك.',
    reset: 'مسح كلمة المرور',
    cancel: 'إلغاء',
    back: 'العودة الى الخيارات السابقة',
    mandatoryFields: 'الحقول التي تحمل علامة * إلزامية',
    errors: {
      invalidEmail: 'يجب أن يكون حقل البريد الإلكتروني عنوان بريد إلكتروني صالح',
    },
  },
  hebrew: {
    signIn: 'התחבר',
    email: 'דואר אלקטרוני',
    error: 'אירעה שגיאה במהלך האיפוס, אנא נסה שנית',
    success: 'דואר אלקטרוני עם איפוס סיסמה נשלח. אם לא קיבלת אותו, אנא ודא שהכנסת את כתובת הדואר האלקטרוני שנרשמת איתה, ובדוק את תיבת הספאם',
    reset: 'אפס סיסמה',
    cancel: 'ביטול/ בטל',
    back: 'חזרה לניווט שלי',
    mandatoryFields: 'שדות המסומנים ב-* הם חובה',
    errors: {
      invalidEmail: 'כתובת האימייל לא תקינה',
    },
  },
}
