export default {
  english: {
    label: 'Quick access',
    content: 'Content',
    menu: 'Menu',
    footer: 'Footer',
  },
  french: {
    label: 'Accès rapide',
    content: 'Contenu',
    menu: 'Menu',
    footer: 'Pied de page',
  },
  arabic: {
    label: 'شريط أدوات الوصول السريع',
    content: 'محتوى',
    menu: 'قائمة طعام',
    footer: 'تذييل',
  },
  hebrew: {
    label: 'גישה מהירה',
    content: 'תוכן',
    menu: 'תפריט',
    footer: '',
  },
}
