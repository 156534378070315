export default {
  english: {
    xLabel: 'i24NEWS on X',
    xLink: 'https://x.com/i24news_EN',
    facebookLabel: 'i24NEWS on Facebook',
    facebookLink: 'https://www.facebook.com/i24newsEN',
    instagramLabel: 'i24NEWS on Instagram',
    instagramLink: 'https://www.instagram.com/i24news/',
    youtubeLabel: 'i24NEWS on YouTube',
    youtubeLink: 'https://www.youtube.com/i24News',
    linkedInLabel: 'i24NEWS on LinkedIn',
    linkedInLink: 'https://www.linkedin.com/company/i24news',
    wikipediaLabel: 'i24NEWS on Wikipedia',
    wikipediaLink: 'https://en.wikipedia.org/wiki/i24NEWS',
    newsletterLabel: 'Subscribe to i24NEWS newsletter',
    newsletterLink: '/en/newsletter',
  },
  french: {
    xLabel: 'i24NEWS sur X',
    xLink: 'https://x.com/i24news_FR',
    facebookLabel: 'i24NEWS sur Facebook',
    facebookLink: 'https://www.facebook.com/i24newsFR',
    instagramLabel: 'i24NEWS sur Instagram',
    instagramLink: 'https://www.instagram.com/i24news_fr/',
    youtubeLabel: 'i24NEWS sur YouTube',
    youtubeLink: 'https://www.youtube.com/user/i24newsFR',
    linkedInLabel: 'i24NEWS sur LinkedIn',
    linkedInLink: 'https://www.linkedin.com/company/i24news',
    wikipediaLabel: 'i24NEWS sur Wikipedia',
    wikipediaLink: 'https://fr.wikipedia.org/wiki/i24NEWS',
    newsletterLabel: 'Abonnez-vous à la newsletter i24NEWS',
    newsletterLink: '/fr/newsletter',
  },
  arabic: {
    xLabel: 'X على i24NEWS',
    xLink: 'https://x.com/i24news_AR',
    facebookLabel: 'Facebook على i24NEWS',
    facebookLink: 'https://www.facebook.com/i24newsAR',
    instagramLabel: 'Instagram على i24NEWS',
    instagramLink: 'https://instagram.com/i24news_ar',
    youtubeLabel: 'YouTube على i24NEWS',
    youtubeLink: 'https://www.youtube.com/user/i24newsAR',
    linkedInLabel: 'LinkedIn على i24NEWS',
    linkedInLink: 'https://www.linkedin.com/company/i24news',
    wikipediaLabel: 'Wikipedia على i24NEWS',
    wikipediaLink: 'https://ar.wikipedia.org/wiki/%D8%A2%D9%8A24%D9%86%D9%8A%D9%88%D8%B2',
    newsletterLabel: 'اشترك في النشرة الإخبارية لـ i24NEWS',
    newsletterLink: '/ar/النشرة-الإخبارية',
  },
  hebrew: {
    xLabel: 'i24NEWS ב X',
    xLink: 'https://x.com/i24news_HE',
    facebookLabel: 'i24NEWS ב META',
    facebookLink: 'https://www.facebook.com/i24newsHE',
    instagramLabel: 'i24NEWS ב Instagram',
    instagramLink: 'https://instagram.com/i24news_HE',
    youtubeLabel: 'i24NEWS ב YouTube',
    youtubeLink: 'https://www.youtube.com/@i24NEWS_HE',
    linkedInLabel: 'i24NEWS ב Linkdin',
    linkedInLink: 'https://www.linkedin.com/company/i24news',
    wikipediaLabel: 'i24NEWS ב Wikipedia',
    wikipediaLink: 'https://he.wikipedia.org/wiki/i24NEWS',
    newsletterLabel: 'הירשם לניוזלטר',
    newsletterLink: '/he/newsletter',
  },
}
