export default {
  english: {
    label: 'Live',
    link: 'https://video.i24news.tv?lang=en',
  },
  french: {
    label: 'Direct',
    link: 'https://video.i24news.tv?lang=fr',
  },
  arabic: {
    label: 'مباشر',
    link: 'https://video.i24news.tv?lang=ar',
  },
  hebrew: {
    label: 'Live',
    link: 'https://video.i24news.tv?lang=he',
  },
}
